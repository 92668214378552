@use "../global" as g;

.lower {
  .mvSec {
    width: 100%;
    position: relative;
    z-index: 1;
    margin-bottom: 100px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    figure {
      width: 100%;
      height: 585px;

      @include g.mq-down(lg) {
        height: 100vw;
      }

      @include g.imgFit();
    }

    picture {
      width: 100%;
      height: 100%;

    }
  }
}
