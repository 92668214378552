@use "../global" as g;

.slide-style-1 {
  padding-left: calc(50vw - 550px);

  @include g.mq-down(lg) {
    width: 100%;

  }

  .slide {
    margin-right: 10px;

    figure {
      width: 380px;

      @include g.mq-down(lg) {
        width: calc(100vw - 60px);
      }
    }

  }

  .slick-prev {
    left: calc(50vw - 550px - 50px);


    @include g.mq-down(lg) {
      left: -12.5px;
    }
  }

  .slick-next {
    right: 70px;

    @include g.mq-down(lg) {
      right: 15px;
    }
  }

  .slick-dots {
    text-align: left;
  }
}

.slide-style-2 {
  padding-left: calc(50vw - 550px);

  @include g.mq-down(lg) {
    padding-left: 0;
  }

  .slide {
    margin-right: 10px;

    @include g.mq-down(lg) {
      margin: 0 5px;
    }

    figure {
      width: 380px;

      @include g.mq-down(lg) {
        width: calc(100vw - 80px);
      }
    }

  }

  .slick-prev {
    left: calc(50vw - 550px - 50px);


    @include g.mq-down(lg) {
      left: 20px;
    }
  }

  .slick-next {
    right: 70px;

    @include g.mq-down(lg) {
      right: 20px;
    }
  }

  .slick-dots {
    text-align: left;

    @include g.mq-down(lg) {
      text-align: center;
    }
  }
}

.slide-style-3 {

  .slide {
    margin: 0 10px;

    @include g.mq-down(lg) {
      margin: 0 5px;
    }

    figure {
      width: 725px;
      height: 483px;

      @include g.mq-down(lg) {
        width: calc(100vw - 80px);
        height: 78.667vw;
      }

      @include g.imgFit();
    }

  }

  .slick-prev {
    left: 80px;


    @include g.mq-down(lg) {
      left: 20px;
    }
  }

  .slick-next {
    right: 80px;

    @include g.mq-down(lg) {
      right: 20px;
    }
  }
}

.slide-style-4 {
  padding-bottom: 40px;

  @include g.mq-down(lg) {
    padding-bottom: 20px;
  }

  .slide {
    margin-right: 10px;

    .card {
      width: 600px;

      @include g.mq-down(lg) {
        width: calc(100vw - 60px);
      }

      figure {
        width: 100%;
        height: 380px;
        margin-bottom: 10px;
        @include g.imgFit();

        @include g.mq-down(lg) {
          height: 64.267vw;
        }
      }

      .caption {
        text-align: center;

        @include g.mq-down(lg) {
          text-align: left;
        }
      }
    }

  }

  .slick-prev {
    left: -50px;

    @include g.mq-down(lg) {
      left: -12.5px;
      top: calc(50% - 25px);
    }
  }

  .slick-next {
    right: 70px;

    @include g.mq-down(lg) {
      right: 15px;
      top: calc(50% - 25px);
    }
  }

  .slick-dots {
    text-align: left;
  }
}

.slide-style-5 {
  margin-left: -30px;
  width: calc(100% + 60px);

  .slide {
    margin: 0 20px;

    @include g.mq-down(lg) {
      margin: 0 10px;
    }
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }
}

.slide-style-6 {

  &::after {
    content: "";
    background: transparent;
    width: 100%;
    height: 1px;
    display: block;
    margin-top: 30px;
  }

  .slide {
    margin-right: 40px;
    border-bottom: 1px solid g.$grey;

    @include g.mq-down(lg) {
      margin-right: 8.8vw;
    }

    .wrap {
      width: 315px;

      @include g.mq-down(lg) {
        width: 66.133vw;
      }

      figure {
        width: 100%;
        height: 23.06vw;
        max-height: 315px;
        margin-bottom: 20px;

        @include g.mq-down(lg) {
          height: 100%;
          height: 66.133vw;
        }

        @include g.imgFit();
      }

      .caption {
        position: relative;
        z-index: 1;

        .komidashi {
          margin-bottom: 10px;
          font-size: 1.8rem;
          letter-spacing: 1px;

          @include g.mq-down(lg) {
            font-size: 1.5rem;
          }
        }

        .read {
          line-height: 2;
          margin-bottom: 30px;
        }
      }
    }
  }

  .slick-prev {
    left: auto;
    right: 86px;
    top: calc(100% + 15px);

    @include g.mq-down(lg) {
      right: 60px;
    }
  }

  .slick-next {
    right: 46px;
    top: calc(100% + 15px);

    @include g.mq-down(lg) {
      right: 20px;
    }
  }

  .slick-dots {
    text-align: left;
  }
}
