@use "../global" as g;

@include g.mq-up(lg) {
  .post img {
    width: inherit;
    max-width: 100%;
    height: auto;
  }

  .post .alignnone {
    margin: 15px 0;
    display: block;
  }

  .post .aligncenter {
    margin: 15px auto;
    display: block;
  }

  .post img.alignleft {
    margin: 0px 20px 5px 0;
    display: inline;
    float: left;
  }

  .post .alignright {
    margin: 0px 0 5px 20px;
    display: inline;
    float: right;
  }

  .post ul li {
    list-style: disc;
    margin-left: 1.5em;
  }

  .post ol li {
    list-style: decimal;
    margin-left: 1.5em;
  }

  .post h1 {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 1.9px;
    margin: 10px 0 46px;
  }

  .post h2 {
    font-size: 15px;
    line-height: 1.13;
    letter-spacing: 1.9px;
    margin: 10px 0 46px;
  }

  .post h3 {
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 1.42;
    margin: 10px 0;
  }

  .post h4 {
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 1.42;
    margin: 10px 0;
  }

  .post h5 {
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 1.42;
    margin: 10px 0;
  }

  .post h6 {
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 1.42;
    margin: 10px 0;
  }

  .post li {
    list-style: disc;
    margin-left: 1em;
    line-height: 2;
  }

  .post a {
    text-decoration: underline;
    color: #736357;
  }

  .post strong {
    font-weight: 700;
  }
}

@include g.mq-down(lg) {
  .post h2 {
    font-size: 1.3rem;
    line-height: 1.6;
    letter-spacing: 0.3rem;
    margin: 10px 0 10px;
  }

  .post h3 {
    font-size: 1.1rem;
    letter-spacing: 0.25rem;
    line-height: 1.55;
    margin: 10px 0 10px;
  }

  .post p {
    margin: 0 0 10px;
  }

  /*
	.post h2,
	.post h3,
	.post p {
	    padding: 0px 3.3%;
	}
	*/
  .post img {
    /*
	    margin: 0px 0 4vw -3.3%;
	    width: 106.6%;
	    */
    width: 100%;
    height: auto;
  }

  .post .alignnone {
    margin: 15px 0;
  }

  .post .aligncenter {
    margin: 15px 0 15px;
    display: block;
    width: 100%;
    max-width: none;
  }

  .post img.alignleft {
    width: 50%;
    margin: 0px 20px 5px 0;
    display: inline;
    float: left;
  }

  .post .alignright {
    width: 50%;
    margin: 0px 0 5px 20px;
    display: inline;
    float: right;
  }

  .post a {
    text-decoration: underline;
    color: #736357;
  }

  .post strong {
    font-weight: 700;
  }
}
