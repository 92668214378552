@use "../global" as g;

.pageHeadSec {
  padding-top: 120px;

  @include g.mq-down(lg) {
    padding-top: 60px;
  }

  .inner {
    width: calc(100% - 60px);
    max-width: 1083px;
    margin: 0 auto;

  }

  &__head {
    text-align: center;
    margin: 0 0 60px;

    @include g.mq-down(lg) {
      margin: 0 0 40px;
    }

    .en {
      font-size: 4rem;
      letter-spacing: 4px;
      font-weight: 700;
      color: g.$brown;

      @include g.mq-down(lg) {
        font-size: 2.8rem;
      }
    }

    .ja {
      color: g.$brown;
      font-size: 1.2rem;
    }
  }
}
