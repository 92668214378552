@use "../global" as g;

.btn {
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }
}

.el_btn {
  background: #fff;
  display: block;
  position: relative;
  padding: 20px 0;
  color: g.$gold;
  text-align: center;
  transition: opacity .2s;
  font-weight: 600;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: g.$gold;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_or {
  background: g.$gold;
  display: block;
  position: relative;
  padding: 20px 0;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  transition: opacity .2s;
  font-weight: 600;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: #fff;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_gl {
  background: #B49E55;
  border: 1px solid #fff;
  color: #fff;
  display: block;
  font-weight: 600;
  padding: 20px 0;
  position: relative;
  text-align: center;
  transition: opacity 0.2s;

  .icon {
    bottom: 0;
    display: inline-block;
    height: 16px;
    margin: auto;
    position: absolute;
    right: 10px;
    top: 0;
    width: 16px;

    svg {
      fill: #fff;
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_gr {
  background: g.$brown ;
  display: block;
  position: relative;
  padding: 20px 0;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  transition: opacity .2s;
  font-weight: 600;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: #fff;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_transparent {
  background: transparent;
  display: block;
  position: relative;
  padding: 20px 0;
  border: 1px solid g.$brown;
  color: g.$brown;
  text-align: center;
  transition: opacity .2s;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: g.$brown;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_telBtn {
  display: block;
  padding: 15px 0;
  border: 1px solid #fff;
  color: #fff;
  font-size: 2rem;
  letter-spacing: 1px;
  text-align: center;
}

.el_btnText {
  padding-bottom: 5px;
  border-bottom: 1px solid g.$gold;
  color: g.$gold;
  transition: opacity .2s;

  @include g.mq-down(lg) {
    width: 100%;
    display: block;
    position: relative;
    padding: 15px 0;
    border: 1px solid g.$gold;
    text-align: center;
  }

  &:hover {
    opacity: .8;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;

    @include g.mq-down(lg) {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      margin: auto;
    }

    svg {
      fill: g.$gold;
    }
  }
}

.el_btnText_wh {
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  color: #fff;
  letter-spacing: .5px;
  transition: opacity .2s;

  @include g.mq-down(lg) {
    display: flex !important;
    justify-content: space-between;
  }

  &:hover {
    opacity: .8;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;

    @include g.mq-down(lg) {}

    svg {
      fill: #fff;
    }
  }
}

.el_btnText_red {
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid g.$red;
  color: g.$red;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 10px;
    vertical-align: middle;

    svg {
      fill: g.$red;
    }
  }
}

.el_btnText_or {
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid g.$gold;
  color: g.$gold;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 10px;
    vertical-align: middle;

    svg {
      fill: g.$gold;
    }
  }
}

.el_btnText_br {
  padding-bottom: 5px;
  border-bottom: 1px solid g.$brown;
  color: g.$brown;
  letter-spacing: .5px;

  @include g.mq-down(lg) {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 10px;
    vertical-align: middle;

    fill: g.$brown;
  }

  &:hover {
    opacity: .8;
  }
}

.el_btnText_gl {
  padding-bottom: 5px;
  border-bottom: 1px solid g.$gold;
  color: g.$gold;
  letter-spacing: .5px;

  @include g.mq-down(lg) {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 10px;
    vertical-align: middle;

    fill: g.$gold;
  }

  &:hover {
    opacity: .8;
  }
}
