@use "../global" as g;

.p-top {
  .mvSec {
    width: 100%;
    height: 100vh;
    min-height: 750px;
    position: relative;
    z-index: 2;

    @include g.mq-down(lg) {
      height: calc(100% - 75px);
      min-height: auto;
      margin-top: 75px;
    }

    &::before {
      content: "";
      background: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 100vh;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      @include g.mq-down(lg) {
        height: calc(100vh - 75px);
      }
    }

    &::after {
      content: "";
      background: rgba(209, 206, 197, .7);
      width: 1px;
      height: calc(12vh + 950px);
      display: block;
      position: absolute;
      top: 78vh;
      left: 50%;

      @include g.mq-down(lg) {
        height: 410px;
        top: 100%;
      }
    }

    .slides {
      .slide {
        width: 100%;
        height: 100vh;

        @include g.mq-down(lg) {
          height: calc(100vh - 75px);
        }

        figure {
          width: 100%;
          height: 100%;
          @include g.imgFit();
        }
      }
    }
  }

  .importantTopicsSec {
    padding: 60px 0 90px;
    position: relative;
    z-index: 2;

    @include g.mq-down(lg) {
      padding: 20px 0 70px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1210px;
      margin: 0 auto;

      a {
        color: g.$gold;
        text-decoration: underline;
      }
    }
  }

  .conceptSec {
    position: relative;
    background: linear-gradient(180deg, transparent 0%, transparent 167px, g.$bg-grey 167px, g.$bg-grey 100%);
    z-index: 1;

    @include g.mq-down(lg) {
      background: linear-gradient(180deg, transparent 0%, transparent 16vw, g.$bg-grey 16vw, g.$bg-grey 100%);
    }

    .o-obj {
      width: 468px;
      height: 468px;
      display: block;
      border: 65px solid g.$bg-grey;
      background: transparent;
      border-radius: 50%;
      position: absolute;
      left: calc(50% - 69px);
      top: -170px;
      transform: translateX(-50%);
      z-index: 3;
      mix-blend-mode: multiply;
      opacity: 0.5;

      @include g.mq-down(lg) {
        border: 45px solid g.$bg-grey;
        width: 274px;
        height: 274px;
        left: 50%;
        top: -105px;
      }
    }

    .deco {
      h1 {
        width: 525px;
        height: 202px;
        padding-left: calc(50vw - 695px);
        box-sizing: unset;
        position: absolute;
        top: 147px;
        z-index: 1;

        @include g.mq-down(lg) {
          width: 82.933vw;
          height: 32vw;
          position: static;
          margin-bottom: 12.267vw;
        }
      }
    }

    .imgs {
      margin-left: calc(50vw + 7px);
      position: absolute;
      top: 0;
      left: 0;

      @include g.mq-down(lg) {
        position: relative;
        margin-left: 0;
        height: 127.459vw;
      }

      .img1 {
        width: 359px;
        height: 514px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;

        @include g.mq-down(lg) {
          width: 64vw;
          height: 91.467vw;
        }
      }

      .img2 {
        width: 326px;
        height: 484px;
        position: absolute;
        top: 270px;
        left: 163px;

        z-index: 1;

        @include g.mq-down(lg) {
          width: 46.667vw;
          height: 69.067vw;
          top: 33.6vw;
          left: 28.8vw;
        }
      }

      .img3 {
        width: 287px;
        height: 403px;
        border: 42px solid #fff;
        box-sizing: unset;
        position: absolute;
        top: 514px;
        left: 405px;
        z-index: 2;

        @include g.mq-down(lg) {
          border: 5.867vw solid #fff;
          width: 43.467vw;
          height: 61.067vw;
          left: 64vw;
          top: 54.133vw;
        }
      }
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1100px;
      margin: 0 auto;
      padding-bottom: 122px;

      @include g.mq-down(lg) {
        padding-bottom: 85px;
      }

      .content-box1 {
        padding-top: 457px;
        padding-left: 100px;
        margin-bottom: 90px;
        position: relative;
        z-index: 1;

        @include g.mq-down(lg) {
          padding-top: 0;
          padding-left: 0;
        }

        &::after {
          content: "";
          display: block;
          width: 259px;
          height: 252px;
          background-image: url(../images/common/obj_moji.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          top: calc(100% - 80px);
          left: calc(50% + 50px);
          z-index: 5;

          @include g.mq-down(lg) {
            width: 174px;
            height: 170px;
            top: calc(100% + 30px);
            left: auto;
            right: 0;
          }
        }

        .midashi {
          font-weight: 700;
          color: g.$brown;
          letter-spacing: 1px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.1rem;
          }
        }

        .catch {
          font-size: 3rem;
          letter-spacing: 2px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 2rem;
          }
        }

        .read {
          line-height: 2;

          @include g.mq-down(lg) {
            font-size: 1.3rem;
          }
        }
      }

      .content-box2 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: -285px;

        @include g.mq-down(lg) {
          display: block;
          margin-left: 0;
        }

        .photo {
          width: 47.58%;
          display: flex;
          justify-content: space-between;
          border: 41px solid #fff;

          @include g.mq-down(lg) {
            width: calc(100% + 30px);
            border: 22px solid #fff;
            border-left: none;
            margin-left: -30px;
          }

          .photo1 {
            width: 254px;
            height: 476px;
            margin: 0;
            @include g.imgFit();

            @include g.mq-down(lg) {
              width: 28.8vw;
              height: 73.067vw;
            }
          }

          .photo2 {
            width: 366px;
            height: 476px;
            margin: 0;
            @include g.imgFit();

            @include g.mq-down(lg) {
              width: calc(100% - 28.8vw);
              height: 73.067vw;
            }
          }
        }

        .text {
          width: 54.28%;
          padding-left: 185px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
            margin-top: 30px;
          }

          .catch {
            font-size: 2.2rem;
            line-height: 1.8;
            letter-spacing: 1px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 2rem;
            }
          }

          .read {
            line-height: 2;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }

  .pickupSec {
    padding: 100px 0 130px;
    overflow: hidden;

    @include g.mq-down(lg) {
      padding: 60px 0 100px;
    }

    .inner {
      padding-left: calc(50vw - 605px);
      position: relative;

      .midashi {
        position: absolute;
        bottom: 30px;
        width: 177px;
        font-size: 3.3rem;
        letter-spacing: 2px;
        line-height: 1.4;
        transform: rotate(-90deg);
        transform-origin: left;
        color: g.$brown;
        margin-left: 25px;

        @include g.mq-down(xl) {
          position: static;
          transform: rotate(0);
          margin-left: 30px;
          margin-bottom: 20px;
        }

        span {
          display: block;
          font-size: 1.0rem;
          letter-spacing: 1px;
        }
      }

      .slides {
        padding-left: 250px;

        @include g.mq-down(xl) {
          padding-left: 30px;
        }

        .slide {
          a {
            display: block;
            width: 255px;
            margin-right: 45px;

            @include g.mq-down(lg) {
              margin-right: 30px;
            }

            figure {
              width: 100%;
              height: g.pcvw(255);
              max-height: 255px;
              margin-bottom: 25px;
              position: relative;
              background: #000;

              @include g.mq-down(lg) {
                margin-bottom: 15px;
                height: g.spvw(234);
              }

              &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                border: 20px solid rgba(255, 255, 255, 0.3);
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                @include g.mq-up(lg) {
                  animation: w-border .6s ease-in-out 0s 1 alternate forwards;
                }

                @include g.mq-down(lg) {
                  border-width: 15px;
                }

              }

              img {
                transition: opacity .6s;
              }

              @include g.imgFit();
            }

            &:hover {
              figure {

                &::before {
                  @include g.mq-up(lg) {
                    animation: b-border .6s ease-in-out 0s 1 alternate forwards;
                  }

                }

                img {
                  @include g.mq-up(lg) {
                    opacity: 0.6;
                  }

                }
              }
            }

            @keyframes b-border {
              0% {
                border: 20px solid rgba(255, 255, 255, 0.3);
              }

              50% {
                border: 0px solid rgba(255, 255, 255, 0.3);
              }

              51% {
                border: 0px solid rgba(0, 0, 0, 0.5);
              }

              100% {
                border: 20px solid rgba(0, 0, 0, 0.5);
              }
            }

            @keyframes w-border {
              0% {
                border: 20px solid rgba(0, 0, 0, 0.5);
              }

              50% {
                border: 0px solid rgba(0, 0, 0, 0.5);
              }

              51% {
                border: 0px solid rgba(255, 255, 255, 0.3);
              }

              100% {
                border: 20px solid rgba(255, 255, 255, 0.3);
              }
            }

            .caption {
              .title {
                font-size: 1.5rem;
                margin-bottom: 3px;
              }

              .more {
                display: inline-block;
                font-size: 1.1rem;
                letter-spacing: 1px;
                border-bottom: 1px solid g.$text-color;
              }
            }
          }
        }

        .slick-prev {
          left: 0;
          top: calc(100% - 12px);

          @include g.mq-down(xl) {
            top: calc(100% + 25px);
            left: 30px;
          }
        }

        .slick-next {
          left: 35px;
          top: calc(100% - 12px);

          @include g.mq-down(xl) {
            top: calc(100% + 25px);
            left: 65px;
          }
        }

        .slick-dots {
          bottom: auto;
          top: calc(100% - 26px);
          text-align: left;
          left: 80px;
          z-index: 0;

          @include g.mq-down(xl) {
            left: 110px;
            top: calc(100% + 10px);
          }
        }

        .slick-list {
          z-index: 5;

          @include g.mq-down(xl) {
            padding-bottom: 20px;
          }
        }
      }
    }
  }

  .ceremonySec {
    position: relative;
    padding-bottom: 80px;

    @include g.mq-down(lg) {
      overflow: hidden;
    }

    &::after {
      content: "";
      display: block;
      width: 534px;
      height: 917px;
      background-image: url(../images/top/obj_concept-01.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: -100px;

      @include g.mq-down(lg) {
        width: 253px;
        height: 434px;
        bottom: -101px;
        left: -77px;
      }
    }

    .imgs {
      position: absolute;
      top: 0;
      //left: 0;
      left: calc(50vw - 705px);
      z-index: 0;
      //width: 48.02%;
      width: 656px;
      height: 828px;

      @include g.mq-down(lg) {
        width: 111.2vw;
        height: 140.267vw;
        position: static;
        margin-bottom: 30.4vw;
      }

      figure {
        width: 100%;
        height: 100%;
        @include g.imgFit();
      }
    }

    .inner {
      padding-left: calc(53vw - 115px);
      position: relative;
      z-index: 1;

      @include g.mq-down(lg) {
        padding: 0;
      }

      .square-obj {
        display: block;
        width: 382px;
        height: 468px;
        background-image: url(../images/common/obj_square.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 233px;
        right: calc(50vw - 528px);
        z-index: -1;

        @include g.mq-down(lg) {
          width: g.spvw(265);
          height: g.spvw(310);
          right: auto;
          left: 50%;
          top: g.spvw(50);
          transform: translateX(-50%);
        }
      }

      h2 {
        .midashi {
          padding-top: 224px;
          width: 551px;
          margin-bottom: 178px;

          @include g.mq-down(lg) {
            width: 80vw;
            height: 21.067vw;
            margin-left: -1.6vw;
            margin-bottom: 21.333vw;
            padding-top: 0;
          }
        }
      }


      .card {
        margin-bottom: 70px;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        @include g.mq-down(lg) {
          width: calc(100% - 60px);
          margin: 0 auto 100px;

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }

        figure {
          width: 100%;
          max-width: 798px;
          height: 489px;
          @include g.imgFit();
          margin-bottom: 30px;

          @include g.mq-down(lg) {
            margin-left: -20px;
            width: calc(100% + 40px);
            height: 94.667vw;
          }
        }

        .caption {
          .category {
            font-size: 1.3rem;
            color: g.$brown;
            letter-spacing: 1px;
          }

          .komidashi {
            font-size: 3.5rem;
            letter-spacing: 4px;
            color: g.$brown;
            margin-bottom: 5px;

            @include g.mq-down(lg) {
              font-size: 3rem;
            }
          }

          .read {
            font-size: 1.8rem;
            color: g.$brown;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }

  .cuisineSec {
    margin-top: -85px;
    margin-bottom: 40px;

    @include g.mq-down(lg) {
      margin-top: -20.333vw;
      margin-bottom: 100px;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1103px;
      margin: 0 auto;
      position: relative;

      .content-box {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @include g.mq-down(lg) {
          display: block;
        }
      }

      .photo {
        width: 54%;
        position: relative;
        z-index: 1;

        @include g.mq-down(lg) {
          width: 100%;
        }
      }

      .text {
        width: calc(46% + 45px);
        margin-left: -45px;
        margin-bottom: 70px;

        @include g.mq-down(lg) {
          width: 100%;
          margin: -20px 0 40px;
        }

        .midashi {
          width: 373px;
          margin-bottom: 40px;

          @include g.mq-down(lg) {
            width: 59.467vw;
            margin-bottom: 30px;
          }
        }

        .wrap {
          padding-left: 130px;

          @include g.mq-down(lg) {
            padding-left: 0;
          }

          .catch {
            font-size: 1.8rem;
            line-height: 1.8;
            color: g.$brown;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              letter-spacing: 2px;
            }

          }

          .read {
            color: g.$brown;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }

  .dressSec {
    margin-bottom: 130px;

    @include g.mq-down(lg) {
      margin-bottom: 0;
      padding-bottom: 60px;
    }

    .inner {
      .content-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        padding-bottom: 170px;
        position: relative;

        @include g.mq-down(lg) {
          display: block;
        }

        &::before {
          content: "";
          width: 434px;
          height: 479px;
          background-image: url(../images/top/obj_dress-02.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;

          @include g.mq-down(lg) {
            width: 180px;
            height: 198px;
          }
        }

        &::after {
          content: "";
          width: 100%;
          height: 287px;
          background: g.$bg-grey;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -2;

          @include g.mq-down(lg) {
            height: 137px;
          }
        }

        .photo {
          background-image: url(../images/top/obj_dress-01.jpg);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 50vw;
          height: 751px;
          display: flex;
          padding-left: 164px;
          align-items: center;
          position: relative;

          @include g.mq-down(xl) {
            padding-left: 12%;
          }

          @include g.mq-down(lg) {
            width: 100%;
            height: 109.867vw;
            padding-left: 0;
            justify-content: center;
            margin-bottom: 30px;
          }

          figure {
            width: 361px;
            height: 534px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              width: 50.4vw;
              height: 78.133vw;
            }
          }

          .midashi {
            width: 748px;
            position: absolute;
            top: 152px;
            right: calc(100% - 190px);
            z-index: 1;

            @include g.mq-down(lg) {
              width: calc(100% + 20px);
              top: 50%;
              right: auto;
              //left: 50%;
              transform: translate(-50%, -50%);
              z-index: 5;
            }
          }
        }

        .text {
          width: 50vw;
          height: 751px;
          padding-left: calc(50vw - 551.5px);
          display: flex;
          justify-content: center;
          align-items: center;

          @include g.mq-down(lg) {
            height: auto;
            width: calc(100% - 60px);
            padding-left: 0;
            display: block;
            margin: 0 auto;

          }

          .catch {
            font-size: 1.8rem;
            line-height: 1.8;
            color: g.$brown;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 2rem;
            }
          }

          .read {
            color: g.$brown;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.3rem;
            }
          }

          .more {
            display: inline-block;
            margin-top: 20px;
          }
        }
      }
    }
  }

  .planSec {
    margin-bottom: 110px;

    @include g.mq-down(lg) {
      margin-bottom: 30px;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1144px;
      margin: 0 auto;

      .midashi {
        width: 628px;
        margin: -150px auto 80px;

        @include g.mq-down(lg) {
          width: 100%;
          margin: 0 auto 40px;
        }
      }

      .slides {
        margin-bottom: 60px;

        @include g.mq-down(lg) {
          margin-left: -30px;
          width: calc(100% + 60px);
        }

        .slide {
          margin: 0 14px;

          @include g.mq-down(lg) {
            margin: 0;
          }

          a {
            display: block;
            width: 258px;

            @include g.mq-down(lg) {
              width: calc(100% - 22px);
              margin: 0 auto;
            }

            figure {
              margin-bottom: 10px;
            }

            .category {
              margin-bottom: 10px;

              span {
                display: inline-block;
                font-size: 1.2rem;
                margin: 0 3px 3px 0;
                color: #fff;
                background: g.$thin-brown;
                padding: 2px 7px;
              }
            }

            .title {
              color: g.$brown;
              font-size: 1.4rem;
              margin-bottom: 10px;
            }

            .price {
              background: g.$bg-grey;
              padding: 3px 10px;
              color: g.$brown;
              font-size: 1.8rem;

              .unit {
                font-size: 1.3rem;
                color: g.$text-color;
              }
            }
          }
        }

        .slick-prev {
          left: -45px;

          @include g.mq-down(xxxl) {
            left: -30px;
          }

          @include g.mq-down(lg) {
            left: 20px;
          }
        }

        .slick-next {
          right: -45px;

          @include g.mq-down(xxxl) {
            right: -30px;
          }

          @include g.mq-down(lg) {
            right: 20px;
          }
        }

        .slick-prev::before,
        .slick-next::before {
          content: "" !important;
          background-image: url(../images/common/btn_slidearrow2.svg);
        }


      }

      .btnwrap {
        text-align: center;
      }
    }
  }

  .topicsSec {
    margin-bottom: 107px;

    .inner {
      padding-left: calc(50vw - 289px);
      position: relative;

      &::before {
        @include g.mq-down(lg) {

          top: 200px;
          left: 50%;
          transform: translateX(-50%);
          width: 279px;
          height: 279px;
          border: 50px solid #F4F1ED;
        }
      }

      .o-obj {
        display: block;
        width: 468px;
        height: 468px;
        border: 60px solid #F4F1ED;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        top: 150px;
        left: calc(50vw - 289px - 280px);
        z-index: -1;
        opacity: .6;
        content: "";


      }

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
        margin: 0 auto;
        padding-left: 0;
      }

      .midashi {
        font-size: 4rem;
        letter-spacing: 4px;
        font-weight: 700;
      }

      .list {
        dl {
          display: flex;
          border-bottom: 1px solid g.$grey;
          padding-bottom: 15px;
          margin-top: 30px;

          @include g.mq-down(lg) {
            display: block;
            padding-bottom: 10px;
            margin-top: 15px;
          }

          dt {
            width: 80px;
            margin-right: 20px;

            @include g.mq-down(lg) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 10px;
            }
          }

          dd {
            width: calc(100% - 100px);
            padding-right: calc(50vw - 551.5px);

            @include g.mq-down(lg) {
              width: 100%;
              padding-right: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .instaSec {
    .inner {
      .account {
        text-align: center;

        a {
          display: inline-block;
          margin-bottom: 20px;
        }

        .icon {
          width: 16px;
          height: 16px;
          display: block;
          margin: 0 auto;

          svg {
            fill: g.$gold;
          }
        }

        .name {
          color: g.$gold;
          font-size: 1.5rem;
          letter-spacing: 1px;
        }
      }
    }
  }
}
