@use "../global" as g;
@use "partyTemplate";


.recommendfairSec {
  margin-bottom: 0;
  background-image: url(../images/ceremony/img_recommendfair-01_pc.jpg);

  @include g.mq-down(lg) {
    background-image: url(../images/ceremony/img_recommendfair-01_sp.jpg);
  }
}
