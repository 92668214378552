@use "../global" as g;

footer {

  .sitemapSec {
    background: g.$bg-grey;
    padding: 70px 0 30px;

    .inner {
      width: calc(100% - 60px);
      max-width: 1210px;
      margin: 0 auto;

      .content-box {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: stretch;

        @include g.mq-down(lg) {
          display: block;
        }

        .inquiries {
          width: 46%;
          margin-left: 8%;

          @include g.mq-down(lg) {
            width: 100%;
            margin-left: 0;
          }

          .midashi {
            font-size: 2rem;
            margin-bottom: 8px;
            letter-spacing: 3px;
            color: g.$gold;

            @include g.mq-down(lg) {
              text-align: center;
              margin-bottom: 14px;
            }
          }

          .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include g.mq-down(lg) {
              display: block;
            }

            li {
              width: calc(50% - 6px);
              margin-top: 12px;

              @include g.mq-down(lg) {
                width: 100%;
                margin-top: 6px;
              }
            }

            &.webform {
              margin-bottom: 40px;

              a {
                .icon {
                  @include g.mq-up(lg) {
                    display: none;
                  }
                }

                &.el_btn_gl {
                  border-color: g.$gold;
                }
              }
            }


            &.telephone {
              li {
                @include g.mq-down(lg) {
                  text-align: center;
                  border-bottom: 1px solid g.$grey;
                  padding-bottom: 20px;
                  margin-bottom: 20px;

                  .komidashi {
                    margin-bottom: 10px;
                  }
                }
              }

              li:first-child {
                border-right: 1px solid g.$grey;

                @include g.mq-down(lg) {
                  border-right: none;

                }
              }

              li:last-child {
                padding-left: 20px;

                @include g.mq-down(lg) {
                  padding-left: 0;
                }
              }

              .number {
                display: block;
                color: g.$gold;
                font-size: 1.6rem;
                letter-spacing: 1px;
                margin-bottom: 5px;

                @include g.mq-down(lg) {
                  border: 1px solid g.$gold;
                  text-align: center;
                  padding: 10px 0;
                  margin-bottom: 10px;
                }

                span {
                  display: inline-block;
                  font-size: 2.1rem;
                }
              }
            }
          }
        }

        .sitemap {
          width: 54%;
          padding-right: 2%;
          border-right: 1px solid g.$grey;

          @include g.mq-down(lg) {
            width: 100%;
            padding-right: 0;
            border-right: 0;
            padding-top: 50px;
          }

          .logo {
            width: 234px;
            height: 40px;
            margin-bottom: 40px;
            position: relative;
            top: -10px;

            @include g.mq-down(lg) {
              width: 175px;
              height: 42px;
              margin: 0 auto 50px;
              position: static;
            }
          }

          .index {
            display: flex;
            justify-content: space-between;
            font-size: 1.2rem;

            &--sp {
              display: none;
            }

            @include g.mq-down(lg) {
              &--pc {
                display: none;
              }

              &--sp {
                display: block;
                font-size: 1.3rem;
              }
            }

            .list {
              li {
                margin-bottom: 15px;

                @include g.mq-down(lg) {
                  text-align: center;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                &.insta {
                  margin-top: 25px;

                  svg {
                    width: 20px;
                    height: 20px;
                    fill: g.$gold;
                  }
                }
              }
            }
          }
        }
      }

      .corp-nav {
        margin-top: 30px;

        .index {
          &--sp {
            display: none;
          }

          @include g.mq-down(lg) {
            &--pc {
              display: none;
            }

            &--sp {
              display: block;
            }
          }


        }

        .list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          li {
            padding: 0 10px;
            line-height: 1;
            letter-spacing: 1px;
            font-size: 1.2rem;

            @include g.mq-down(lg) {
              width: auto;
            }

            &::after {
              content: "";
              width: 1px;
              height: 100%;
              background: g.$text-color;
              display: inline-block;
              margin-left: 20px;

              @include g.mq-down(lg) {
                content: none;
              }
            }

            &:last-child {
              &::after {
                content: none;
              }

              @include g.mq-down(lg) {
                width: 100%;
                text-align: center;
                margin-top: 20px;

                .icon {
                  width: 18px;
                  height: 18px;
                  display: inline-block;
                  vertical-align: middle;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

    }
  }

  .copyrightSec {
    background: #9297AC;
    text-align: center;
    padding: 25px 0;
    color: #fff;
    line-height: 1;
    letter-spacing: 1px;

    @include g.mq-down(lg) {
      padding: 25px 0 40px;
      text-align: left;
      background: g.$bg-grey;
      color: g.$text-color;

      div {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        p {
          font-size: 1rem;

          span {
            display: inline;
          }
        }

        .corp-logo {
          width: 65px;
          height: 30px;
        }

      }

    }
  }

  .pcGroupSec {
    background: #9297AC;
    padding: 70px 0 80px;
    color: #fff;

    .inner {
      width: calc(100% - 40px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        margin-bottom: 40px;

        span {
          display: inline-block;
          vertical-align: middle;

          .en {
            margin-right: 20px;
            font-size: 1.8rem;
            letter-spacing: 1px;
          }
        }
      }

      .contentBox {
        display: flex;
        align-items: stretch;

        .col {
          width: 380px;

          .block {
            margin-bottom: 3rem;
            font-size: 1.2rem;
            line-height: 2.8;

            .komidashi {
              font-weight: 700;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }


}
