@use "../global" as g;

.faqSec {
  padding: 70px 0 140px;

  @include g.mq-down(lg) {
    padding: 60px 0 100px;
  }

  .inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }

  .head {
    padding-bottom: 55px;
    color: g.$brown;
    text-align: center;

    @include g.mq-down(lg) {
      padding-bottom: 30px;
    }

    .ja,
    .en {
      display: block;
    }

    .en {
      margin-bottom: 10px;
      font-size: 30px;
      letter-spacing: .1em;

      @include g.mq-down(lg) {
        font-size: 20px;
      }
    }

    .ja {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: .1em;
    }
  }

  .list {
    margin-bottom: 30px;

    dt {
      background: g.$bg-grey;
      margin-bottom: 24px;
      padding: 30px 0;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: .05em;

      @include g.mq-down(lg) {
        margin-bottom: 20px;
        padding: 20px 5%;
        font-size: 13px;
        line-height: 1.5;
      }

      &::before {
        content: "Q";
        top: 27px;

        @include g.mq-down(lg) {
          top: 15px;
        }
      }
    }

    dd {
      font-size: 13px;
      font-weight: 500;
      line-height: 2.07;
      letter-spacing: .05em;

      @include g.mq-down(lg) {
        padding-right: 5%;
      }

      &::before {
        content: "A";
        top: -5px;

        @include g.mq-down(lg) {
          top: -5px;
        }
      }

      +dt {
        margin-top: 55px;

        @include g.mq-down(lg) {
          margin-top: 40px;
        }
      }
    }

    dt,
    dd {
      position: relative;
      padding-left: 64px;

      @include g.mq-down(lg) {
        padding-left: 50px;
      }

      &::before {
        position: absolute;
        left: 30px;
        color: g.$brown;
        font-family: "Cormorant Garamond", serif;
        font-size: 18px;
        letter-spacing: .1em;

        @include g.mq-down(lg) {
          left: 20px;
        }
      }
    }
  }

  .more {
    text-align: center;
    padding-top: 30px;
    font-weight: bold;

    @include g.mq-down(lg) {
      padding-top: 20px;
      width: calc(100% - 60px);
      margin: 0 auto;
    }
  }
}
