@use "../global" as g;

.p-faq {
  .selectSec {
    &.--upper {
      margin-bottom: 60px;
      margin-top: -20px;

      @include g.mq-down(lg) {
        margin-bottom: 50px;
      }
    }

    &.--bottom {
      margin-top: 80px;
      margin-bottom: 140px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1086px;
      margin: 0 auto;

      .list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 0 3px;

        @include g.mq-down(lg) {
          display: block;
        }

        .item {
          width: 33.333%;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 5px;

            &:nth-last-of-type(1) {
              margin-bottom: 0;
            }
          }

          a {
            display: block;
            text-align: center;
            background: g.$grey;
            color: #fff;
            font-weight: 700;
            padding: 15px 0;

          }

          &.is-active {
            a {
              background: g.$brown;
            }
          }
        }
      }
    }
  }

  .contentSec {
    .details {
      background: g.$bg-grey;
      padding: 80px 0 90px;

      @include g.mq-down(lg) {
        padding: 40px 0 45px;
      }

      &:nth-of-type(even) {
        background: #fff;
      }

      .inner {
        width: calc(100% - 40px);
        max-width: 980px;
        margin: 0 auto;

        .midashi {
          font-size: 2rem;
          text-align: center;
          color: g.$brown;
        }

        .list {
          margin-top: 45px;
          line-height: 2;

          @include g.mq-down(lg) {
            margin-top: 30px;
            line-height: 1.6;
          }

          dt {
            border-bottom: 1px solid g.$grey;
            padding-bottom: 13px;
            margin-bottom: 13px;
            display: flex;
            align-items: stretch;

            @include g.mq-down(lg) {
              padding-bottom: 10px;
              margin-bottom: 10px;
            }

            .badge {
              color: g.$brown;
              font-size: 2rem;
              padding-right: 20px;
              line-height: 1;
              margin-top: 3px;

              @include g.mq-down(lg) {
                margin-top: -2px;
              }
            }

            .read {
              font-size: 1.6rem;

              @include g.mq-down(lg) {
                font-size: 1.4rem;
              }
            }

          }

          dd {
            display: flex;
            align-items: stretch;

            .badge {
              color: g.$brown;
              font-size: 2rem;
              padding-right: 20px;
              line-height: 1;
              margin-top: 3px;
            }

            .btn {
              display: inline-block;
              margin-top: 15px;
              margin-right: 30px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
