@use "../global" as g;

//フッターフロートナビゲーション
.fixed_footer_link {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 5;

  @include g.mq-down(md) {
    width: 100%;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid g.$gold;

    /* z-index: 43; */
    a {
      background-color: #4f93a0;
      width: 240px;
      display: block;
      position: relative;
      padding: 20px 0 16px;
      /* width: 50%; */
      color: #fff;
      line-height: 1;
      text-align: center;

      @include g.mq-down(md) {
        width: 33.3333%;
      }

      span {
        display: inline-block;

        @include g.mq-down(md) {
          //padding-left: 15px;
        }
      }

      &.tel_link {
        background: #fff;
        border-left: 1px solid g.$gold;
        color: g.$gold;
        font-weight: 600;

        @include g.mq-up(lg) {
          display: none;
        }

        //&::before {
        //  background-image: url(../images/common/mail-org.svg);
        //}
        i {
          width: 16px;
          display: inline-block;
          margin-top: 1px;
          margin-right: 5px;
          vertical-align: top;
        }
      }

      &.contact_link {
        background: #fff;
        color: g.$gold;
        font-weight: 600;



        //&::before {
        //  background-image: url(../images/common/mail-org.svg);
        //}
        i {
          width: 16px;
          display: inline-block;
          margin-top: 1px;
          margin-right: 5px;
          vertical-align: top;
        }
      }

      &.request_link {
        background: #fff;
        border-right: 1px solid g.$gold;
        color: g.$gold;
        font-weight: 600;

        @include g.mq-down(lg) {
          display: none;
        }

        ;

        //&::before {
        //  background-image: url(../images/common/shiryo.svg);
        //}
        i {
          width: 16px;
          display: inline-block;
          margin-top: 1px;
          margin-right: 5px;
          vertical-align: top;
        }
      }

      &.reserve_link {
        background: g.$gold;
        font-weight: 600;

        i {
          width: 16px;
          display: inline-block;
          margin-top: -2px;
          margin-right: 5px;
          vertical-align: top;
        }

        //&::before {
        //  background-image: url(../images/common/calendar-wh.svg);
        //}
      }

      &.tel_link {
        background: #fff;
        color: g.$orange;

        @include g.mq-up(md) {
          display: none;
        }

        //&::before {
        //  background-image: url(../images/common/tel-org.svg);
        //}
        i {
          width: 16px;
          display: inline-block;
          margin-top: -2px;
          margin-right: 5px;
          vertical-align: top;
        }
      }

      &::before {
        content: "";
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);

        background-position: center;

        @include g.mq-down(md) {
          width: 16px;
          height: 16px;
          left: 10px;
        }
      }
    }
  }
}
