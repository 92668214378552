@use "../global" as g;

.topicpathSec {
  background: #fff;
  padding: 10px 40px;
  color: #959595;

  @include g.mq-down(lg) {
    padding: 10px 20px;
  }
}
