@use "../global" as g;

.p-beginner {
  .pageHeadSec {
    &__head {
      .en {
        font-size: 2.5rem;
      }
    }
  }

  .outlineSec {
    margin-bottom: 80px;



    .inner {
      width: calc(100% - 60px);
      margin: 0 auto;


      .read {
        font-size: 1.8rem;
        line-height: 2;
        letter-spacing: 2px;
        text-align: center;

        @include g.mq-down(lg) {
          font-size: 1.4rem;
        }
      }
    }
  }

  .introductionSec {
    margin-bottom: 90px;

    @include g.mq-down(lg) {
      margin-bottom: 50px;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        margin-bottom: 10px;
        color: g.$brown;
        text-align: right;

        @include g.mq-down(lg) {
          margin-bottom: 40px;
          text-align: left;
        }

        .en {
          position: relative;
          font-size: 2.8rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 2rem;
          }

          span {
            background: #fff;
            display: inline-block;
            position: relative;
            z-index: 1;
            padding-left: 40px;

            @include g.mq-down(lg) {
              padding-right: 20px;
              padding-left: 0;
            }
          }

          &::before {
            content: "";
            background: g.$bg-grey;
            width: 100%;
            height: 1px;
            display: block;
            position: absolute;
            top: 20px;
            right: 0;
            z-index: 0;

            @include g.mq-down(lg) {
              width: calc(100% + 30px);
              top: 16px;
              right: auto;
              left: 0;
            }
          }
        }

        .ja {
          font-size: 1.3rem;
        }
      }

      .list {
        li {
          @include g.mq-down(lg) {
            margin-bottom: 50px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &:nth-child(2n) {
            @include g.mq-up(lg) {
              margin-top: 60px;
            }
          }

          &:nth-child(3n) {
            @include g.mq-up(lg) {
              margin-top: 120px;
            }
          }

          figure {
            margin-bottom: 25px;
            height: 30.747vw;
            max-height: 420px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              height: 83.733vw;
              margin-bottom: 10px;
            }
          }

          .point {
            color: g.$brown;
            font-size: 3rem;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              margin-bottom: 10px;
              line-height: 1;
            }

            span {
              font-size: 1.8rem;
              letter-spacing: 1px;
            }
          }

          .komidashi {
            margin-bottom: 15px;
            font-size: 2rem;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
            }
          }

          .read {
            line-height: 1.8;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: .05em;
          }
        }
      }
    }
  }

  .experienceSec {
    background: g.$bg-grey;
    padding: 90px 0 70px;

    @include g.mq-down(lg) {
      padding: 60px 0 80px;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        margin-bottom: 35px;
        color: g.$brown;
        text-align: center;

        .en {
          margin-bottom: 5px;
          font-size: 2.8rem;
          letter-spacing: 3px;

          @include g.mq-down(lg) {
            font-size: 2.0rem;
          }
        }

        .ja {
          letter-spacing: 1px;
        }
      }

      .list {
        margin-bottom: 40px;

        li {
          @include g.mq-down(lg) {
            margin-bottom: 40px;
          }

          figure {
            margin-bottom: 18px;
            height: 19.431vw;
            max-height: 246px;

            @include g.mq-down(lg) {
              height: 56vw;
              max-height: none;
              margin-bottom: 10px;
            }

            @include g.imgFit();
          }
        }

        .caption {
          .komidashi {
            margin-bottom: 13px;
            color: g.$brown;
            font-size: 1.6rem;
            letter-spacing: 1px;

            @include g.mq-down(lg) {
              margin-bottom: 8px;
            }
          }

          .read {
            line-height: 1.8;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: .05em;
          }
        }
      }

      .btns {
        padding-top: 40px;
        border-top: 1px solid g.$grey;

        .note {
          margin-bottom: 30px;
          text-align: center;
        }

        .list {
          display: flex;
          justify-content: center;

          @include g.mq-down(lg) {
            display: block;
            margin-bottom: 0;
          }

          li {
            width: 291px;
            margin: 0 5px;

            @include g.mq-down(lg) {
              width: 100%;
              margin-bottom: 10px;
              letter-spacing: 0.1em;
              font-size: 13px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            a {
              border: 1px solid g.$gold;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .faqSec {
    padding: 70px 0 140px;

    @include g.mq-down(lg) {
      padding: 60px 0 100px;
    }

    .inner {
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
    }

    .head {
      padding-bottom: 55px;
      color: g.$brown;
      text-align: center;

      @include g.mq-down(lg) {
        padding-bottom: 30px;
      }

      .ja,
      .en {
        display: block;
      }

      .en {
        margin-bottom: 10px;
        font-size: 30px;
        letter-spacing: .1em;

        @include g.mq-down(lg) {
          font-size: 20px;
        }
      }

      .ja {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: .1em;
      }
    }

    .list {
      margin-bottom: 30px;

      dt {
        background: g.$bg-grey;
        margin-bottom: 24px;
        padding: 30px 0;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: .05em;

        @include g.mq-down(lg) {
          margin-bottom: 20px;
          padding: 20px 5%;
          font-size: 13px;
          line-height: 1.5;
        }

        &::before {
          content: "Q";
          top: 27px;

          @include g.mq-down(lg) {
            top: 15px;
          }
        }
      }

      dd {
        font-size: 13px;
        font-weight: 500;
        line-height: 2.07;
        letter-spacing: .05em;

        @include g.mq-down(lg) {
          padding-right: 5%;
        }

        &::before {
          content: "A";
          top: -5px;

          @include g.mq-down(lg) {
            top: -5px;
          }
        }

        +dt {
          margin-top: 55px;

          @include g.mq-down(lg) {
            margin-top: 40px;
          }
        }
      }

      dt,
      dd {
        position: relative;
        padding-left: 64px;

        @include g.mq-down(lg) {
          padding-left: 50px;
        }

        &::before {
          position: absolute;
          left: 30px;
          color: g.$brown;
          font-family: "Cormorant Garamond", serif;
          font-size: 18px;
          letter-spacing: .1em;

          @include g.mq-down(lg) {
            left: 20px;
          }
        }
      }
    }

    .more {
      text-align: center;
      padding-top: 30px;
      font-weight: bold;

      @include g.mq-down(lg) {
        padding-top: 20px;
        width: calc(100% - 60px);
        margin: 0 auto;
      }
    }
  }
}
