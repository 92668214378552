@use "../global" as g;

.p-gallery {
  .pageHeadSec {
    padding: 60px 0 100px;

    @include g.mq-down(lg) {
      padding: 40px 0 50px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1080px;
      margin: 0 auto;

      .title {
        margin-bottom: 10px;

        .en {
          font-size: 3.4rem;
          letter-spacing: 1px;
        }
      }

      .copy {
        font-size: 1.7rem;
        letter-spacing: 1px;
      }
    }
  }

  .tabSec {
    margin-bottom: 60px;

    &.__bottom {
      padding-top: 60px;
    }

    .inner {
      width: 1206px;
      margin: 0 auto;

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
      }

      .tabs {
        display: flex;
        flex-wrap: wrap;
        gap: 1.3px;
        margin-bottom: 120px;
        justify-content: space-between;

        li {
          background: g.$grey;
          //width: 14.2%;
          width: 16.5%;
          color: #fff;
          text-align: center;

          @include g.mq-down(lg) {
            width: calc(50% - 1px);
            margin-bottom: 2px;
          }

          a {
            width: 100%;
            height: 100%;
            display: block;
            padding: 13px 0;
            letter-spacing: 2px;
          }

          &.active {
            background: g.$brown;
          }
        }
      }
    }
  }

  .archiveSec {
    padding: 50px 0 20px;
    background: g.$bg-grey;

    @include g.mq-down(lg) {
      padding-bottom: 90px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1206px;
      margin: 0 auto;
    }

    .midashi {
      text-align: center;
      color: g.$brown;
      font-size: 2rem;
      letter-spacing: 2px;
      margin-bottom: 25px;
    }

    .subcat {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;

      @include g.mq-down(lg) {
        display: flex;
        width: 100%;
        margin-bottom: 30px;
        flex-wrap: wrap;
        justify-content: center;
      }

      a {
        display: inline-block;
        margin: 0 10px;
        color: #a0a6a0;
        font-size: 1.3rem;

        @include g.mq-down(lg) {
          display: block;
          text-align: center;
          margin-bottom: 8px;
          padding: 5px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.active {
          color: g.$brown;
          font-weight: 700;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      @include g.mq-down(lg) {
        justify-content: space-between;
      }

      li {
        width: 25%;
        padding: 0 1.333%;
        margin-bottom: 30px;
        box-sizing: border-box;

        @include g.mq-down(lg) {
          width: 48%;
          padding: 0px;
          margin: 0;
        }

        //&:nth-child(4n) {
        //  margin-right: 0;
        //}

        a {
          display: block;

          &:hover {
            figure {
              @include g.mq-up(lg) {
                opacity: .8;
              }
            }

            .caption {
              .more {
                text-decoration: none;
              }
            }
          }
        }

        figure {
          width: 100%;
          height: 275px;
          transition: opacity .2s;
          @include g.imgFit();

          @include g.mq-down(lg) {
            height: 42.667vw;
            margin-bottom: 15px;
          }
        }
      }
    }

    .is-animate {
      opacity: 1;
      animation: .6s zoom-in;
    }

    @keyframes zoom-in {
      0% {
        opacity: 0.3;
        transform: scale(.8);
      }

      100% {
        opacity: 1;
        transform: none;
      }
    }
  }

  .linksSec {
    background: g.$bg-grey;
    padding: 0px 0 140px;

    @include g.mq-down(lg) {
      padding: 0px 0 90px;
    }

    .inner {
      width: calc(100% - 40px);
      margin: 0 auto;

      ul {
        display: flex;
        justify-content: center;

        @include g.mq-down(lg) {
          display: block;
        }

        li {
          margin: 0 30px;

          @include g.mq-down(lg) {
            margin: 0 0 20px;
          }

          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 280px;

            @include g.mq-down(md) {
              width: 100%;
            }
          }
        }
      }
    }
  }



  .mfp-container {
    .mfp-arrow-left {
      left: calc(50% - 545px);
      opacity: 1;

      @include g.mq-down(lg) {
        left: -10px;
      }
    }

    .mfp-arrow-right {
      right: calc(50% - 545px);
      opacity: 1;

      @include g.mq-down(lg) {
        right: -10px;
      }
    }
  }

}
