@use "../global" as g;

.p-access {
  .mvSec {
    @include g.mq-down(lg) {
      margin-bottom: 50px;
    }
  }

  .outline {
    margin-bottom: 185px;

    @include g.mq-down(lg) {
      margin-bottom: 40px;
    }

    .catch {
      font-size: 2.4rem;
      letter-spacing: 2px;
      margin-bottom: 15px;

      @include g.mq-down(lg) {
        font-size: 1.8rem;
        text-align: center;
      }
    }

    .read {
      line-height: 2;
    }
  }

  .accessSec {
    background: linear-gradient(to bottom, transparent 0%, transparent 320px, g.$bg-grey 320px, g.$bg-grey 100%);

    @include g.mq-down(lg) {
      background: linear-gradient(to bottom, transparent 0%, transparent 100.6vw, g.$bg-grey 100.6vw, g.$bg-grey 100%);

    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1086px;
      margin: 0 auto;

      .content-box {
        display: flex;
        justify-content: space-between;
        padding-bottom: 70px;

        @include g.mq-down(lg) {
          display: block;
          padding-bottom: 60px;
        }

        .imgs {
          width: 432px;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 40px;
          }
        }

        .text {
          width: 50%;
          padding-right: 53px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-right: 0;
          }

          dl {
            display: flex;
            margin-bottom: 60px;
            line-height: 2;

            @include g.mq-down(lg) {
              display: block;
              margin-bottom: 15px;
            }

            &:nth-last-of-type(1) {
              margin-bottom: 0;
            }

            dt {
              width: 85px;
              font-weight: 700;
              color: g.$brown;

              @include g.mq-down(lg) {
                width: 100%;
              }
            }

            dd {
              width: calc(100% - 85px);
              padding-left: 30px;
              border-left: 1px solid g.$grey;

              @include g.mq-down(lg) {
                width: 100%;
                padding-left: 0;
                border-left: none;
              }


              .card {
                margin-bottom: 10px;

                &:nth-last-of-type(1) {
                  margin-bottom: 0;
                }

                .komidashi {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }

  .mapSec {
    padding-bottom: 140px;

    @include g.mq-down(lg) {
      padding-bottom: 90px;
    }

    .map {
      iframe {
        width: 100%;
        height: 520px;

        @include g.mq-down(lg) {
          height: 100vw;
        }
      }
    }

    .more {
      font-weight: 700;
      text-align: center;
      margin-top: 30px;

      @include g.mq-down(lg) {
        width: 100px;
        margin: 20px auto 0;
        align-items: center;
      }
    }

  }
}
