@use "../global" as g;
@use "partyTemplate";

.p-novelcrystal {
  .outlineSec {
    .inner {
      &::before {
        content: "";
        display: block;
        background-image: url(../images/top/obj_concept-01.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 315px;
        height: 542px;
        position: absolute;
        left: -90px;
        bottom: 450px;

        @include g.mq-down(lg) {
          bottom: -240px;
          left: -110px;
          width: 253px;
          height: 434px;
          z-index: -1;
        }
      }
    }
  }

  .recommendfairSec {
    margin-bottom: 0;
    background-image: url(../images/novelcrystal/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/novelcrystal/img_recommendfair-01_sp.jpg);
    }
  }
}
