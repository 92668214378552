@use "../global" as g;

.p-report {
  .pageHeadSec {
    padding: 60px 0 100px;

    @include g.mq-down(lg) {
      padding: 40px 0 50px;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1080px;
      margin: 0 auto;

      .title {
        margin-bottom: 10px;

        .en {
          font-size: 3.4rem;
          letter-spacing: 1px;
        }
      }

      .copy {
        font-size: 1.7rem;
        letter-spacing: 1px;
      }
    }
  }

  .tabSec {
    margin-bottom: 60px;

    .inner {
      width: 722px;
      margin: 0 auto;

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
      }

      ul {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(lg) {
          display: block;
        }

        li {
          width: 49.9%;
          position: relative;

          @include g.mq-down(lg) {
            width: 100%;
          }

          .el_btn_gr {
            .icon {
              transform: rotate(90deg);
            }
          }

          a,
          span {
            background: g.$brown;
            cursor: pointer;

            &.active {
              background: g.$brown;
            }
          }

          .accordion {
            display: none;
            width: 100%;
            position: absolute;
            top: 62px;
            left: 0;
            z-index: 5;

            a {
              padding: 12px 0;
            }
          }
        }
      }
    }
  }

  .archiveSec {
    margin-bottom: 170px;

    @include g.mq-down(lg) {
      margin-bottom: 100px;
    }

    ul {
      width: calc(100% - 60px);
      max-width: 1080px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      @include g.mq-down(lg) {
        width: calc(100% - 80px);
        display: block;
      }

      li {
        width: 23.15%;
        margin-right: 2.4666%;
        margin-bottom: 70px;

        @include g.mq-down(lg) {
          width: 100%;
          margin: 0 auto 30px;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }

        a {
          display: block;

          &:hover {
            figure {
              @include g.mq-up(lg) {
                opacity: .8;
              }
            }

            .caption {
              .more {
                text-decoration: none;
              }
            }
          }
        }

        figure {
          width: 100%;
          height: 250px;
          margin-bottom: 10px;
          transition: opacity .2s;
          @include g.imgFit();

          @include g.mq-down(lg) {
            height: 56vw;
            margin-bottom: 15px;
          }
        }

        .caption {
          .name {
            font-size: 1.6rem;
            color: g.$brown;
            border-bottom: 1px solid g.$bg-grey;
            padding-bottom: 3px;
            margin-bottom: 6px;
            letter-spacing: 1px;
          }

          .date {
            color: g.$brown;
            font-size: 1.2rem;
            letter-spacing: 1px;
            margin-bottom: 6px;
          }

          .place {
            font-size: 1.2rem;
            margin-bottom: 3px;
          }

          .more {
            color: g.$brown;
            text-decoration: underline;
          }
        }
      }
    }

    .wp-pagenavi {
      margin-top: 60px;
      text-align: center;

      @include g.mq-down(lg) {
        margin-top: 30px;
      }

      span {
        display: inline-block;
        border: none;
        color: #999999;
        z-index: 1;
        padding: 0 5px;
        font-weight: 700;
      }

      a {
        border: none;
        color: g.$brown;
        padding: 0 5px;
      }
    }
  }
}
