@use "../global" as g;

.p-concept {
  .outlineSec {
    padding-top: 250px;
    background: linear-gradient(180deg, transparent 0%, transparent 344px, g.$bg-grey 344px, g.$bg-grey 100%);
    position: relative;

    @include g.mq-down(lg) {
      padding-top: 60px;

      &::after {
        border: 45px solid #F4F1ED;
        height: 274px;
        left: 50%;
        top: -45px;
        width: 274px;
      }
    }



    .o-obj {
      content: "";
      width: 468px;
      height: 468px;
      display: block;
      border: 70px solid g.$bg-grey;
      background: transparent;
      border-radius: 50%;
      position: absolute;
      left: calc(50vw - 60px);
      top: -20px;
      z-index: 0;
      mix-blend-mode: multiply;
    }

    .content-box1 {
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: row-reverse;
      position: relative;
      margin-bottom: 110px;

      @include g.mq-down(lg) {
        display: block;
        margin-bottom: 60px;
      }

      .deco {
        position: absolute;
        top: -70px;
        left: 0;
        padding-left: calc(50vw - 603px);
        width: 505px;
        height: 202px;
        box-sizing: unset;

        @include g.mq-down(lg) {
          position: static;
          width: calc(100% - 50px);
          height: auto;
          margin: 0 30px 50px 20px;

        }
      }

      .imgs {
        width: 50%;
        display: flex;
        justify-content: space-between;
        gap: 6px;

        @include g.mq-down(lg) {
          width: calc(100% - 20px);
          margin-left: 20px;
          margin-bottom: 30px;
        }

        .img1 {
          width: 55%;
          height: 513px;

          @include g.mq-down(lg) {
            height: 74.667vw;
          }

          @include g.imgFit();
        }

        .img2 {
          width: 45%;
          height: 513px;

          @include g.mq-down(lg) {
            height: 74.667vw;
          }

          @include g.imgFit();
        }


      }

      .text {
        width: 50%;
        padding-left: calc(50vw - 550px);

        @include g.mq-down(lg) {
          width: calc(100% - 60px);
          margin: 0 auto;
          padding-left: 0;
        }

        .midashi {
          font-weight: 700;
          color: g.$brown;
          letter-spacing: 1px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.1rem;
          }
        }

        .catch {
          font-size: 3rem;
          letter-spacing: 2px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 2.6rem;
          }
        }

        .read {
          line-height: 2;

          @include g.mq-down(lg) {
            font-size: 1.3rem;
          }
        }
      }
    }

    .content-box2 {
      display: flex;
      justify-content: space-between;

      @include g.mq-down(lg) {
        display: block;
        margin-left: 0;
        padding-bottom: 60px;
      }

      .photo {
        width: 47.44%;
        height: 418px;

        @include g.mq-down(lg) {
          width: calc(100% - 20px);
          height: 94.667vw;
        }

        figure {
          width: 100%;
          height: 100%;
          @include g.imgFit();
        }
      }

      .text {
        width: 54.28%;
        padding-left: 115px;
        margin-top: 60px;

        @include g.mq-down(lg) {
          width: calc(100% - 60px);
          padding-left: 0;
          margin: 30px auto 0;
        }

        .catch {
          font-size: 2.2rem;
          line-height: 1.8;
          letter-spacing: 1px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 2rem;
          }
        }

        .read {
          line-height: 2;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  .reasonSec {
    padding: 110px 0 130px;
    position: relative;
    background: linear-gradient(180deg, transparent 0%, transparent calc(100% - 903px), g.$bg-grey calc(100% - 903px), g.$bg-grey 100%);

    @include g.mq-down(lg) {
      padding: 39.467vw 0 70px;
    }

    &::before {
      content: "";
      width: 764px;
      height: 683px;
      background-image: url(../images/concept/obj_reason-01.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -167px;
      left: -205px;
      z-index: -1;

      @include g.mq-down(lg) {
        width: 122.133vw;
        height: 109.067vw;
        top: -25.067vw;
        left: -22.133vw;
      }
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1286px;
      margin: 0 auto;

      .midashi {
        h2 {
          .en {
            color: g.$brown;
            font-size: 2.4rem;
            letter-spacing: 4px;
            margin-bottom: 5px;

            @include g.mq-down(lg) {
              font-size: 2.2rem;
            }
          }
        }

        margin-bottom: 110px;
        text-align: center;

        @include g.mq-down(lg) {
          margin-bottom: 60px;
        }



        .ja {
          font-size: 2.2rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 2rem;
          }
        }
      }

      .content-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 100px;

        @include g.mq-down(lg) {
          display: block;
          margin-bottom: 50px;
        }

        &.reverse {
          flex-direction: row-reverse;

          .text {
            padding-left: 93px;
            padding-right: 9.8%;

            @include g.mq-down(lg) {
              padding: 0;
            }
          }
        }

        .photo {
          width: 50%;
          height: 378px;

          @include g.mq-down(lg) {
            width: 100%;
            height: 89.333vw;
            margin-bottom: 30px;
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }

        .text {
          width: 50%;
          padding-left: 9.8%;
          padding-right: 40px;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0;
          }

          .komidashi {
            color: g.$brown;
            font-size: 1.2rem;
            font-weight: 700;
            letter-spacing: 1px;
            margin-bottom: 10px;
          }

          .catch {
            font-size: 2rem;
            line-height: 1.8;
            margin-bottom: 20px;
          }

          .read {
            line-height: 2;
          }

          .btn {
            display: inline-block;
            margin-top: 20px;

            @include g.mq-down(lg) {
              display: flex;
            }
          }
        }
      }


    }

    .slides {
      margin-left: calc(50vw - 550px);

      @include g.mq-down(lg) {
        margin-left: 20px;
      }

      .slide {
        .wrap {
          width: 320px;
          margin-right: 45px;

          @include g.mq-down(lg) {
            width: 64vw;
            margin-right: 8vw;
          }

          figure {
            width: 100%;
            margin-bottom: 13px;
          }

          .job {
            margin-bottom: 10px;

            .en {
              display: block;
              font-size: 1.2rem;
              font-weight: 700;
              color: g.$brown;
              letter-spacing: 1px;
              margin-bottom: 3px;
            }

            .ja {
              display: block;
              font-size: 1.6rem;
              letter-spacing: 2px;
            }
          }

          .read {
            text-align: justify;
          }
        }
      }
    }
  }

  .contentsSec {
    padding: 110px 0 120px;
    position: relative;

    @include g.mq-down(lg) {
      padding: 60px 0 120px;
    }

    .square-obj {
      display: block;
      width: 382px;
      height: 468px;
      background-image: url(../images/common/obj_square.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0px;
      left: calc(55% + 55px);
      z-index: 0;
      content: "";
    }

    .midashi {
      padding-left: calc(50vw - 550px);
      font-size: 2.6rem;
      letter-spacing: 3px;
      color: g.$brown;
      margin-bottom: 40px;
      position: relative;

      @include g.mq-down(lg) {
        font-size: 2.2rem;
        padding-left: 0;
        margin-left: 20px;
        margin-bottom: 30px;
      }

      span {
        display: inline-block;
        background: #fff;
        padding-right: 20px;

      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: g.$grey;
        z-index: -1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50vw - 550px);

        @include g.mq-down(lg) {
          left: 0;
        }
      }
    }

    .slides {
      margin-left: calc(50vw - 550px);
      position: relative;
      z-index: 1;

      @include g.mq-down(lg) {
        margin-left: 20px;
      }

      .slide {
        .wrap {
          width: 240px;
          margin-right: 34px;

          @include g.mq-down(lg) {
            width: 64vw;
            margin-right: 8vw;
          }

          figure {
            width: 100%;
            margin-bottom: 13px;
          }

          .title {
            text-align: center;
            color: g.$brown;

            .en {
              display: block;
              font-size: 2rem;
              font-weight: 700;
              letter-spacing: 2px;
              margin-bottom: 3px;
            }
          }

          .read {
            text-align: justify;
          }
        }
      }
    }
  }
}
