@use "../global" as g;

header {
  background: transparent;
  //background: #fff;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  &::after {
    @include g.mq-down(lg) {
      content: "";
      width: 100%;
      height: 75px;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  @include g.mq-down(lg) {
    height: 75px;
  }

  .inner {
    position: relative;

    h1 {
      padding-top: 23px;
      padding-left: 20px;
      font-size: 1rem;

      @include g.mq-down(lg) {
        display: none;
      }
    }

    .logo {
      width: 182px;
      height: 31px;
      padding: 36px 0 0 36px;
      box-sizing: content-box;

      @include g.mq-down(lg) {
        width: 146px;
        height: 35px;
        position: static;
        padding-top: 24px;
        padding-left: 20px;
        transform: none;
      }
    }

    .box {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 100px;

      @include g.mq-down(lg) {
        //display: none;
        right: 75px;
      }

      .tel {
        @include g.mq-down(lg) {
          display: none;
        }

        p {
          color: g.$gold;
          font-size: 1.4rem;
          letter-spacing: 1px;
          transition: color .2s;

          &.active {
            color: #fff !important;
          }
        }

        small {
          display: block;
          font-size: 1rem;
        }
      }

      .insta {

        width: 16px;
        height: 16px;
        margin-left: 30px;
        line-height: 1;

        @include g.mq-down(lg) {
          width: 20px;
          height: 20px;
        }

        svg {
          width: 18px;
          height: 18px;

          fill: g.$gold;
          transition: fill .2s;

          &.active {
            fill: #fff !important;
          }

          @include g.mq-down(lg) {
            width: 22px;
            height: 22px;
            fill: #897D65;
            transition: fill .2s;


          }
        }
      }

      .fairReserve {
        background: g.$gold;
        width: 110px;
        height: 110px;
        margin-left: 30px;
        color: #fff;

        a {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;

          p {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            svg {
              width: 26px;
              height: 26px;
              display: block;
              margin: 0 auto 10px;

              fill: #fff;
            }

            span {
              display: block;
              font-size: 1.2rem;
              font-weight: 700;
              line-height: 1.3;
              text-align: center;
            }
          }
        }
      }
    }

    .menu-btn {
      background: #fff;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      @include g.mq-down(lg) {
        right: 0px;
      }

      .word {
        color: g.$text-color;
        font-size: 1.1rem;
        line-height: 1;
        letter-spacing: 2.3px;
        text-align: center;
        margin: 0;
        //transition: letter-spacing .2s, margin .2s;

        @include g.mq-down(lg) {
          font-size: 1rem;
          letter-spacing: 0;
        }

        &.active {
          margin-top: 5px;
          letter-spacing: 1px;

          @include g.mq-down(lg) {
            margin-left: -3px;
          }
        }
      }
    }

    .nav {
      background: #fff;
      width: 100%;
      max-width: 753px;
      height: calc(100vh - 100px);
      visibility: hidden;
      position: absolute;
      top: 100px;
      right: 0;
      padding: 100px 140px 100px 102px;
      box-sizing: border-box;
      opacity: 0;
      overflow-x: hidden;
      overflow-y: scroll;

      @include g.mq-down(lg) {
        width: 100%;
        height: calc(100vh - 0px);
        max-width: none;
        top: 0px;
        padding: 75px 30px 120px 30px;
        box-sizing: border-box;
        z-index: -1;
      }

      .content-box {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(lg) {
          display: block;
        }

        .block {
          width: 46%;

          @include g.mq-down(lg) {
            width: 100%;
          }


          ul {
            @include g.mq-down(lg) {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;

              li {
                width: calc(50% - 10px);
              }
            }
          }

          .inquiries {
            .komidashi {
              margin: 35px 0 25px;
              color: g.$gold;
              font-size: 1.6rem;
              letter-spacing: 1px;

              @include g.mq-down(lg) {
                margin: 50px 0 10px;
              }
            }

            ul {
              @include g.mq-down(lg) {
                display: block;

                li {
                  width: 100%;
                }
              }
            }

            svg {
              fill: g.$gold;
            }
          }
        }

        .sp-insta {
          display: none;

          @include g.mq-down(lg) {
            width: 27px;
            height: 27px;
            margin: 40px auto 0;

            svg {
              width: 100%;
              height: 100%;
              display: block;
              fill: g.$gold;
            }
          }
        }
      }

      ul {
        li {
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;
            padding-bottom: 15px;
            border-bottom: 1px solid #d0d0d0;

            @include g.mq-down(lg) {
              height: 53px;
              justify-content: space-between;
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }

          .ja {
            width: 214px;

            @include g.mq-down(lg) {
              width: calc(100% - 18px);
            }
          }

          svg {
            width: 28px;
            height: 18px;
            position: relative;
            top: 2px;
            padding-left: 10px;

            fill: g.$brown;

            @include g.mq-down(lg) {
              position: static;
            }
          }
        }
      }
    }
  }

  .ResvBtn {
    background: g.$gold;
    width: 100px;
    height: 100px;
    position: relative;
    margin-left: 30px;
    cursor: pointer;

    @include g.mq-down(lg) {
      width: 75px;
      height: 75px;
      margin-left: 20px;
    }

    &>div {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      figure {
        width: 28px;
        height: 28px;
        margin: 0 auto 8px;

        @include g.mq-down(lg) {
          width: 18px;
          height: 18px;
          margin-bottom: 6px;
        }
      }

      .word {
        color: #fff;
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1.6;
        letter-spacing: 0.5px;
        text-align: center;

        @include g.mq-down(lg) {
          font-size: 1.3rem;
          font-weight: 600;
        }

        span {
          display: inline-block;
          margin-bottom: 5px;
          font-size: 1.6rem;
        }
      }
    }
  }

  .ResvNav {
    background: g.$gold;
    width: 480px;
    height: 100%;
    position: fixed;
    top: 0px;
    padding-top: 110px;
    z-index: -1;
    right: 0;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: scroll;

    @include g.mq-down(lg) {
      width: 100%;
      height: 100%;
      padding-top: 80px;
      text-align: center;
      top: 0;
    }

    .midashi {
      margin: 40px 0 20px;
      font-size: 2.1rem;
      letter-spacing: 4px;
      text-align: center;

      span {
        display: block;
        margin-top: 5px;
        font-size: 1.3rem;
      }
    }

    .block {
      margin-bottom: 30px;
      padding: 0 70px;


      &:nth-of-type(2) {
        &::after {
          content: "";
          display: block;
          margin-top: 15px;
          margin-bottom: -15px;
          border-bottom: 1px solid g.$grey
        }
      }

      @include g.mq-down(lg) {
        margin-bottom: 30px;
        padding: 0 40px;
      }
    }

    ul {
      li {
        margin-bottom: 10px;
        color: g.$gold;

        small {
          display: block;
          margin-top: 10px;
          color: #fff;
          text-align: center;
        }
      }
    }

    .komidashi {
      margin-bottom: 5px;

      @include g.mq-down(lg) {
        margin-bottom: 10px;
        text-align: center;
        font-size: 1.3rem;
      }
    }

    .tel {
      display: block;
      margin-bottom: 5px;
      color: #fff;
      font-size: 1.6rem;
      letter-spacing: 1px;

      span {
        font-size: 2.1rem;
      }

      @include g.mq-down(lg) {
        font-weight: 500;
      }

      @include g.mq-up(lg) {
        pointer-events: none;
      }

      @include g.mq-down(lg) {
        margin-bottom: 10px;
        padding: 15px;
        text-align: center;
        font-size: 1.8rem;
        color: g.$gold;
        background-color: #fff;
      }

      .openHour {
        @include g.mq-down(lg) {
          text-align: center
        }
      }
    }
  }

  .menu-btn {
    width: 100px;
    height: 100px;
    padding: 36.5px 30px 36.5px;

    @include g.mq-down(lg) {
      width: 75px;
      height: 75px;
      padding: 22px 23px 32px;
    }

    .menu-trigger,
    .menu-trigger span {
      display: inline-block;
      box-sizing: border-box;
      transition: all .2s;
    }

    .menu-trigger {
      width: 100%;
      height: 20px;
      position: relative;
    }

    .menu-trigger span {
      background-color: g.$text-color;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
    }

    //.menu-trigger.active span {
    //    background-color: #FFFFFF;
    //}
    .menu-trigger span:nth-of-type(1) {
      top: 0;
      transform: translateY(0) rotate(0);
    }

    .menu-trigger span:nth-of-type(2) {
      top: 9px;
    }

    .menu-trigger span:nth-of-type(3) {
      bottom: 0;
      transform: translateY(0) rotate(0);
    }

    /* Animation*/
    .menu-trigger.active span:nth-of-type(1) {
      top: 50%;
      transform: rotate(45deg);
    }

    .menu-trigger.active span:nth-of-type(2) {
      transform: scaleX(0);
    }

    .menu-trigger.active span:nth-of-type(3) {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
}
