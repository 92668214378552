@use "../global" as g;

//モーダル
.mfp-bg {
  background-color: g.$grey !important;
}

.inline-wrap {
  background: #fff;
  width: calc(100% - 40px);
  max-width: 587px;
  position: relative;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

  .image {
    //width: 547px;
    //height: 445px;
    margin: 0 auto 10px;

    @include g.mq-down(lg) {
      width: 100%;
    }


    figure {
      width: 100%;
      height: 100%;

      @include g.imgFit();
    }
  }

  .caption {
    color: g.$brown;
    padding: 0 10px 10px;
    position: relative;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;

    span {
      display: inline-block;
      margin: 0 10px 3px 0;
    }
  }

  .popup-modal-dismiss {
    height: 14px;
    color: g.$brown;
    display: flex;
    position: absolute;
    // bottom: -35px;
    //left: 50%;
    top: 0;
    right: 10px;
    font-weight: 700;
    letter-spacing: 1px;

    //transform: translateX(-50%);
    a {
      display: flex;
      align-items: center;

      .iconClose {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-left: 10px;

        img {
          display: block;
        }
      }
    }

  }
}

.mfp-container {
  .mfp-arrow-left {
    left: calc(50% - 345px);
    opacity: 1;

    @include g.mq-down(lg) {
      left: -10px;
    }

    &::before {
      content: none;
    }

    &::after {
      background: url(../images/common/btn_slidearrow.svg) 50% no-repeat;
      background-size: cover;
      width: 30px;
      height: 30px;
      border: none;
      transform: rotate(-180deg);
    }
  }

  .mfp-arrow-right {
    right: calc(50% - 345px);
    opacity: 1;

    @include g.mq-down(lg) {
      right: -10px;
    }

    &::before {
      content: none;
    }

    &::after {
      background: url(../images/common/btn_slidearrow.svg) 50% no-repeat;
      background-size: cover;
      width: 30px;
      height: 30px;
      margin-left: 31px;
      border: none;
    }
  }
}

//ギャラリーページのモーダル
.gallery {
  .inline-wrap {
    padding: 0 0 20px;

    //max-width: 770px;
    //max-height: 80vh;

    .image {
      width: 100%;
      //max-height: 80vh;
      margin-bottom: 20px;
    }

    .caption {
      padding: 0 20px;
      position: relative;
      font-weight: 700;

      .popup-modal-dismiss {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        color: g.$brown;
      }
    }
  }
}
