@use "../global" as g;

.p-dress {
  .outlineSec {
    margin-bottom: 95px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1206px;
      margin: 0 auto;

      .contentBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: 50%;

          @include g.mq-down(lg) {
            width: 100%;
            height: 84vw;
            margin-bottom: 40px;

            figure {
              width: 100%;
              height: 100%;

              @include g.imgFit();
            }
          }
        }

        .text {
          width: 50%;
          padding: 0 53px 0 96px;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0;
          }

          .catch {
            margin-bottom: 20px;
            font-size: 2rem;
            line-height: 2;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
            }
          }

          .read {
            line-height: 1.8;
          }
        }
      }
    }
  }

  .primacaraSec {
    margin-bottom: 120px;

    .inner {
      padding-left: calc(50vw - 550px);
      max-width: 1232px;
      box-sizing: unset;

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
        margin: 0 auto;
        padding: 0;
      }

      .contentBox {

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: calc(100% - 480px);
          height: 424px;

          @include g.mq-down(lg) {
            width: calc(100% + 30px);
            height: 51.733vw;
            margin-bottom: 40px;
          }

          figure {
            width: 100%;
            height: 100%;

            video {
              width: 100%;
              height: 100%;

              object-fit: cover;
            }
          }
        }

        .text {
          width: 480px;
          padding-right: 95px;
          box-sizing: border-box;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0;
          }

          .midashi {
            margin-bottom: 30px;

            .submidashi {
              margin-bottom: 15px;
              color: g.$brown;
              letter-spacing: 2px;

              @include g.mq-down(lg) {
                margin-bottom: 10px;
              }
            }

            .en {
              width: 289px;
              margin-bottom: 20px;

              @include g.mq-down(lg) {
                width: 260px;
                margin-bottom: 10px;
              }
            }

            .ja {
              color: g.$brown;
            }
          }

          .read {
            margin-bottom: 30px;
            line-height: 1.8;
          }
        }
      }
    }
  }

  .dressContentSec {
    background: g.$bg-grey;
    padding: 140px 0;

    @include g.mq-down(lg) {
      padding: 80px 0;
    }

    .box {
      margin-bottom: 90px;


      @include g.mq-down(lg) {
        margin-bottom: 80px;
        padding-left: 30px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .midashi {
        padding-left: calc(50vw - 550px);
        margin-bottom: 45px;
        color: g.$brown;

        @include g.mq-down(lg) {
          margin-bottom: 30px;
        }

        .en {
          font-size: 2.8rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 2.4rem;
          }
        }
      }
    }

    .more {

      text-align: center;

      @include g.mq-down(lg) {
        width: calc(100% - 30px);
        margin: 0 auto;
      }
    }
  }

  .recommendfairSec {
    background-image: url(../images/weddingdress/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/weddingdress/img_recommendfair-01_sp.jpg);
    }
  }
}
