@use "../global" as g;

.p-partyTemplate {
  .mvSec {
    margin-bottom: 160px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }
  }

  .outlineSec {
    position: relative;
    padding-bottom: 100px;

    .inner {
      padding-left: calc(50vw - 550px);
      position: relative;

      @include g.mq-down(lg) {
        padding-left: 0;
        width: 100%;
      }

      .square-obj {
        width: 382px;
        height: 468px;
        display: block;
        background-image: url(../images/common/obj_square.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 276px;
        left: calc(50vw - 550px + 186px);
        z-index: -1;

        @include g.mq-down(lg) {
          width: g.spvw(265);
          height: g.spvw(310);
          top: 90px;
          left: calc(50vw - 56px);
        }
      }

      .content-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 210px;

        @include g.mq-down(lg) {
          display: block;

          width: calc(100% - 60px);
          margin: 0 auto 60px;
        }

        .text {
          width: 414px;
          padding-bottom: 80px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
            padding-bottom: 40px;

          }
        }

        .midashi {
          color: g.$brown;
          margin-bottom: 20px;

          .en {
            letter-spacing: 4px;
            font-size: 2.4rem;
          }
        }

        .catch {
          letter-spacing: 2px;
          font-size: 2.4rem;
          line-height: 2;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
            margin-bottom: 20px;

          }
        }

        .read {
          line-height: 2;
          margin-bottom: 20px;
        }

        .spec {
          display: flex;

          @include g.mq-down(lg) {
            display: block;
          }

          dt {
            color: g.$brown;
            font-weight: 700;
            margin-right: 80px;
            letter-spacing: 1px;
          }

          dd {
            span {
              display: inline-block;

              &:first-child {
                margin-right: 60px;
              }
            }
          }
        }

        .photo {
          width: calc(100% - 414px);

          @include g.mq-down(lg) {
            width: 100%;
          }

          figure {
            width: 100%;
            height: 510px;
            @include g.imgFit();
            margin-bottom: 40px;

            @include g.mq-down(lg) {
              height: 84vw;
            }
          }
        }
      }

      .slides {
        @include g.mq-down(lg) {
          margin-left: 30px;
        }

      }

      .more {
        margin-top: 60px;

        @include g.mq-down(lg) {
          width: calc(100% - 30px);
          margin: 60px auto 0;
        }
      }
    }
  }

  .styleSec {
    padding: 100px 0;
    background: g.$bg-grey2;

    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        color: g.$brown;
        margin-bottom: 40px;

        .en {
          font-size: 2.4rem;
          letter-spacing: 3px;
        }
      }

      .list {
        .card {
          figure {
            width: 100%;
            height: 22vw;
            max-height: 246px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              height: 56vw;
              max-height: none;
            }

            @include g.imgFit();
          }

          .caption {
            .title {
              font-size: 1.6rem;
              color: g.$brown;
              margin-bottom: 10px;
            }

            .more {
              margin-top: 20px;

              a {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
