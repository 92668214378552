@use "../global" as g;

.recommendfairSec {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 150px 0;
  position: relative;
  margin-bottom: 143px;

  @include g.mq-down(lg) {
    padding: 100px 0;
    margin-bottom: 100px;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#000000, 0.3);
    z-index: 0;
  }

  .inner {
    position: relative;
    z-index: 1;

    .block {
      text-align: center;

      .midashi {
        font-size: 1.4rem;
        letter-spacing: 2px;
        color: #fff;
        margin-bottom: 15px;
      }

      .title {
        font-size: 2.3rem;
        color: #fff;
        margin-bottom: 30px;
      }

      .more {
        width: 255px;
        margin: 0 auto;

        .btn {
          border-color: g.$gold;
        }

      }
    }


  }
}
