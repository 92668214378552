@use "../global" as g;
@use "partyTemplate";

.p-dolcecrystal {
  .recommendfairSec {
    margin-bottom: 0;
    background-image: url(../images/dolcecrystal/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/dolcecrystal/img_recommendfair-01_sp.jpg);
    }
  }
}
