@use "../global" as g;

.lower {
  main {
    margin-top: 100px;

    @include g.mq-down(lg) {
      margin-top: 75px;
    }
  }
}

.card-list-4col {
  display: flex;
  justify-content: space-between;
  gap: 3.64%;

  @include g.mq-down(lg) {
    display: block;
  }

  .card {
    width: 25%;
    max-width: 246px;

    @include g.mq-down(lg) {
      width: 100%;
      max-width: none;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card-list-2col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5.47%;

  @include g.mq-down(lg) {
    display: block;
  }

  .card {
    width: 47.26%;

    @include g.mq-down(lg) {
      width: 100%;
    }
  }
}

.card-list-3col {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 4.15%;

  @include g.mq-down(lg) {
    display: block;
  }

  .card {
    width: 30.27%;

    @include g.mq-down(lg) {
      width: 100%;
    }
  }
}

.p-blog {
  aside {
    @include g.mq-down(lg) {
      width: 100%;
      margin-top: 0;
      margin-bottom: 60px;
    }

    .asideBlock {
      margin-bottom: 40px;
      color: g.$brown;

      .komidashi {
        margin-bottom: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid g.$grey;
        font-size: 1.6rem;
        letter-spacing: 3px;
      }

      li {
        margin-bottom: 20px;
      }

      .select-box {
        width: 100%;
        padding: 5px 0;
        border: none;
        color: #999;

        @include g.mq-down(md) {
          padding: 15px 0;
        }
      }

      .selectbox {
        width: 100%;
        position: relative;
        overflow: hidden;

        @include g.mq-down(lg) {
          width: 100%;
        }
      }

      .selectbox select {
        background: #fff;
        background-image: none;
        width: 100%;
        padding: 10px 38px 10px 5px;
        padding-right: 1em;
        border: none;
        border: 1px solid g.$grey;
        color: g.$grey;
        text-indent: .01px;
        cursor: pointer;

        -webkit-appearance: none;
        appearance: none;
        box-shadow: none;

        @include g.mq-down(md) {
          padding: 7px 38px 7px 7px;
        }

        outline: none;
        text-overflow: ellipsis;
      }

      .selectbox select::-ms-expand {
        display: none;
      }

      .selectbox::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        padding: 0;
        border-top: 6px solid g.$grey;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;

        pointer-events: none;
      }
    }
  }
}
