@use "../global" as g;

.p-facility {
  .mvSec {
    margin-bottom: 100px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }
  }

  .outlineSec {
    margin-bottom: 104px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      width: calc(100% - 60px);
      margin: 0 auto;

      .catch {
        font-size: 2.4rem;
        letter-spacing: 2px;
        line-height: 2;
        text-align: center;
        margin-bottom: 10px;

        @include g.mq-down(lg) {
          font-size: 1.9rem;
          text-align: left;
        }
      }

      .read {
        text-align: center;
        line-height: 2;

        @include g.mq-down(lg) {
          text-align: left;
        }
      }
    }
  }

  .roomSec {
    padding: 0 0 100px;

    @include g.mq-down(lg) {
      padding: 0 0 60px;
    }

    .description {
      margin-bottom: 70px;

      @include g.mq-down(lg) {
        margin-bottom: 40px;
      }

      .content-box {
        display: flex;
        align-items: center;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: 57.1%;

          @include g.mq-down(lg) {
            width: calc(100% - 10px);
            margin-bottom: 30px;
          }

          figure {
            width: 100%;
            height: 500px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              height: 97.333vw;
            }
          }
        }

        .text {
          width: 453px;
          padding-left: 109px;

          @include g.mq-down(lg) {
            width: calc(100% - 60px);
            margin: 0 auto;
            padding: 0;
          }

          .midashi {
            color: g.$brown;
            margin-bottom: 20px;

            .badge {
              font-size: 1.6rem;
              letter-spacing: 2px;
              margin-bottom: 5px;

              @include g.mq-down(lg) {
                font-size: 1.4rem;
                margin-bottom: 0;
              }
            }

            .en {
              font-size: 2.8rem;
              letter-spacing: 4px;

              @include g.mq-down(lg) {
                font-size: 2.4rem;
              }
            }
          }

          .catch {
            font-size: 2rem;
            line-height: 2;
            letter-spacing: 2px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
            }
          }

          .read {
            line-height: 2;
            margin-bottom: 30px;
            text-align: justify;
          }
        }
      }
    }

    .subroom {
      width: calc(100% - 60px);
      max-width: 1206px;
      margin: 0 auto;

      .card {
        @include g.mq-down(lg) {
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        figure {
          width: 100%;
          height: 27.818vw;
          max-height: 380px;
          @include g.imgFit();

          @include g.mq-down(lg) {
            width: calc(100% + 30px);
            height: 64vw;
            margin-bottom: 20px;
          }
        }

        .caption {
          .midashi {
            display: flex;
            align-items: center;
            color: g.$brown;

            @include g.mq-down(lg) {
              display: block;
              margin-bottom: 10px;
            }

            .en {
              font-size: 2.6rem;
              letter-spacing: 4px;
              margin-right: 20px;

              @include g.mq-down(lg) {
                font-size: 2.2rem
              }
            }
          }

          .read {
            line-height: 2;
          }
        }
      }
    }
  }

  .otherroomSec {
    padding: 60px 0 80px;
    background: g.$bg-grey;

    .inner {
      width: calc(100% - 60px);
      max-width: 1206px;
      margin: 0 auto;

      .card {
        @include g.mq-down(lg) {
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        figure {
          width: 100%;
          height: 16.471vw;
          max-height: 225px;
          @include g.imgFit();
          margin-bottom: 10px;

          @include g.mq-down(lg) {
            height: 56vw;
          }
        }

        .caption {
          .midashi {
            color: g.$brown;
            margin-bottom: 10px;

            .en {
              font-size: 2.2rem;
              letter-spacing: 3px;
            }
          }

          .read {
            line-height: 2;
          }
        }
      }
    }
  }

  .gallerySec {
    padding: 100px 0 0;

    .more {
      text-align: center;
      margin-top: 50px;

      @include g.mq-down(lg) {
        width: calc(100% - 30px);
        margin: 50px auto 0;
      }
    }
  }

}
