@use "../global" as g;

.commonfairSec {

  .p-top & {
    padding: 0 0 100px;
    background-color: #f1efef;

    @include g.mq-down(lg) {
      background-color: #fff;
    }
  }

  .inner {
    .midashi {
      width: 543px;
      margin: 0 auto -44px;
      position: relative;
      top: -20px;

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
        margin: -26px auto 0px;
        top: 2.333vw;
      }
    }
  }
}
