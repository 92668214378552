@use "../global" as g;

.p-cuisine {
  .mvSec {
    margin-bottom: 160px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }
  }

  .outlineSec {
    position: relative;
    margin-bottom: 148px;

    @include g.mq-down(lg) {
      margin-bottom: 100px;
    }

    &::after {
      content: "";
      display: block;
      width: calc(50vw + 550px - 73px);
      height: calc(100% - 300px - 150px);
      background: g.$bg-grey;
      position: absolute;
      top: 150px;
      left: 0;
      z-index: -1;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1100px;
      margin: 0 auto 134px;

      @include g.mq-down(lg) {
        margin-bottom: 100px;
      }

      .content-box {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          display: block;
        }

      }

      .text {
        width: 50%;
        padding-left: 95px;

        @include g.mq-down(lg) {
          width: 100%;
          padding-left: 0;
          margin-bottom: 40px;
        }

        figure {
          height: 467px;
          padding-left: 79px;
          margin-bottom: 30px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 30px;
          }

          @include g.imgFit();
        }

        .midashi {
          color: g.$brown;
          letter-spacing: 4px;
          font-size: 2.4rem;
          margin-bottom: 15px;
        }

        .catch {
          letter-spacing: 2px;
          font-size: 2.1rem;
          line-height: 2;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
          }
        }

        .read {
          line-height: 2;
        }
      }

      .photo {
        width: 50%;

        @include g.mq-down(lg) {
          width: 100%;
        }

        .img1 {
          margin-bottom: 60px;
          height: 353px;

          @include g.mq-down(lg) {
            height: 53.333vw;
            margin-bottom: 50px;
          }

          @include g.imgFit();
        }

        .img2 {
          margin-left: -53px;
          width: calc(100% + 53px);
          height: 386px;

          @include g.mq-down(lg) {
            height: 58.667vw
          }

          @include g.imgFit();
        }
      }
    }
  }

  .mediaSec {

    .content-box {
      display: flex;
      align-items: center;
      padding-right: calc(50vw - 550px);

      @include g.mq-down(lg) {
        display: block;
      }

      .photo {
        width: 64%;
        height: 510px;
        display: flex;
        justify-content: space-between;
        gap: 6px;

        @include g.mq-down(lg) {
          width: calc(100% - 30px);
          height: 80vw;
          margin-bottom: 40px;
        }

        figure {
          width: 50%;
          height: 100%;
          @include g.imgFit();
        }
      }

      .text {
        padding-left: 120px;
        max-width: 413px;

        @include g.mq-down(lg) {
          padding-left: 0;
          width: calc(100% - 60px);
          margin: 0 auto;
        }

        .midashi {
          color: g.$brown;
          margin-bottom: 15px;

          .en {
            font-size: 2.6rem;
            letter-spacing: 4px;
          }
        }

        .catch {
          font-size: 2rem;
          letter-spacing: 2px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
          }
        }

        .read {
          line-height: 2;
        }
      }
    }

    &.reverse {
      .content-box {
        padding-left: calc(50vw - 550px);
        padding-right: 0;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          padding-left: 30px;
        }

        .photo {
          @include g.mq-down(lg) {
            width: calc(100% + 30px);
          }
        }

        .text {
          padding-right: 95px;
          padding-left: 0;

          @include g.mq-down(lg) {
            padding: 0 30px 0 0;
            width: 100%;
            margin: 0 auto;
            max-width: none;


          }
        }
      }
    }
  }

  .featureSec {
    margin-bottom: 184px;

    @include g.mq-down(md) {
      margin-bottom: 80px;
    }
  }

  .sweetsSec {
    padding-bottom: 120px;

    @include g.mq-down(lg) {
      padding-bottom: 100px;
    }

    .content-box {
      margin-bottom: 100px;

      @include g.mq-down(lg) {
        margin-bottom: 80px;
      }
    }

    .more {
      margin-top: 60px;
      text-align: center;

      @include g.mq-down(lg) {
        width: calc(100% - 30px);
        margin: 90px auto 0;
      }
    }
  }

  .recommendfairSec {
    background-image: url(../images/cuisine/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/cuisine/img_recommendfair-01_sp.jpg);
    }
  }


}
