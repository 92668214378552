@use "../global" as g;

.p-party {
  .mvSec {
    margin-bottom: 100px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }
  }

  .outlineSec {
    margin-bottom: 104px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      width: calc(100% - 60px);
      margin: 0 auto;

      .catch {
        font-size: 2.4rem;
        letter-spacing: 2px;
        line-height: 2;
        text-align: center;
        margin-bottom: 10px;

        @include g.mq-down(lg) {
          font-size: 1.9rem;
        }
      }

      .read {
        text-align: center;
        line-height: 2;

        @include g.mq-down(lg) {
          text-align: left;
        }
      }
    }
  }

  .mediaSec {
    padding: 100px 0 90px;

    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .description {
      margin-bottom: 70px;

      @include g.mq-down(lg) {
        margin-bottom: 40px;
      }

      .content-box {
        display: flex;
        align-items: center;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: 57.1%;

          @include g.mq-down(lg) {
            width: calc(100% - 30px);
            margin-bottom: 30px;
          }

          figure {
            width: 100%;
            height: 500px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              height: 92vw;
            }
          }
        }

        .text {
          width: 453px;
          padding-left: 109px;

          @include g.mq-down(lg) {
            width: calc(100% - 60px);
            margin: 0 auto;
            padding: 0;
          }

          .midashi {
            color: g.$brown;
            margin-bottom: 20px;

            .en {
              font-size: 3rem;
              letter-spacing: 4px;

              @include g.mq-down(lg) {
                font-size: 2.4rem;
              }
            }
          }

          .catch {
            font-size: 2rem;
            line-height: 2;
            letter-spacing: 2px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
            }
          }

          .read {
            line-height: 2;
            margin-bottom: 30px;
            text-align: justify;
          }
        }
      }
    }

    .imgs {
      padding-left: calc(50vw - 427px);
      display: flex;
      gap: 36px;

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
        margin: 0 auto;
        padding: 0;
        flex-wrap: wrap;
        gap: 5px;
      }

      figure {
        width: 25.329vw;
        height: 25.329vw;
        max-width: 346px;
        max-height: 346px;
        margin: 0;
        @include g.imgFit();

        img {
          object-position: 50% 30%;
        }

        @include g.mq-down(lg) {
          &.img1 {
            width: 41.333vw;
            height: 41.333vw;
          }

          &.img2 {
            width: 41.333vw;
            height: 41.333vw;
          }

          &.img3 {
            width: 100%;
            height: 41.333vw;

            img {
              object-position: 50% 90%;
            }
          }
        }
      }
    }

    .more {
      @include g.mq-down(lg) {
        width: calc(100% - 30px);
        margin: 30px auto 0;
      }
    }
  }

  .novelSec,
  .brunoSec {
    background: g.$bg-grey;
  }

  .brunoSec {
    padding-bottom: 140px;
  }


}
